/// JWT Call helper for base Actions
import { baseAPIUrl, jwtData } from "../../config/config";

export const config = {
  authToken: (jwtData && jwtData.authToken) ? jwtData.authToken : `auth_token`,
  refreshToken: (jwtData && jwtData.refreshToken) ? jwtData.refreshToken : `auth_token`,
};

export const fullEndpoint = (endpoint: string) => {
  if (endpoint && endpoint.startsWith("http")) {
    return `/${endpoint}`;
  } else {
    return `${baseAPIUrl}/${endpoint}`;
  }
};

export const getHeaders = () => {
  const token = localStorage.getItem(config.authToken);
  const headers : { Authorization?: string } = {};

  if(token) {
    headers.Authorization = `Bearer ${token}`;
  }

  return headers;
};

export const getConfig = (overrideParams = {}) => {
  return {
    headers: getHeaders(),
    ...overrideParams
  };
};
