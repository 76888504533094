import Question, { QuestionDataType } from '../question/question';
import styles from './questionnaireTable.module.scss';
import DeleteTableRow from 'components/icons/deleteTableRow';
import EditTableRow from 'components/icons/editTableRow';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { EditTableModal } from 'components/questionnaire/editTableModal/editTableModal';
import { Section, TableQuestionRecord } from 'actions/questionnaireActions';
import { getErroredRecordIds, getFieldId, parseHTML, IntStatus } from 'utils/commonFunctions';
import { useMemo, useState } from 'react';
import { AddTableRowParams, DeleteTableRowParams, ShouldRenderTableQuestionParams, TableModalCloseParams, TableModalSaveParams, UpdateGridQuestionParams, UpdateTableQuestionParams } from 'components/questionnaire/questionnaireHook';
import { GradientTextButton } from 'components/UI/gradientTextButton/gradientTextButton';
import useScreenWidth from 'hooks/useScreenWidth';

type QuestionnaireTableStyles = {
  width: string;
  padding?: string;
  overflow?: string;
  height?: string;
  display?: 'block' | 'none' | 'flex';
  alignItems?: 'center' | 'stretch';
};

interface QuestionnaireTableProps {
  section: Section;
  updateTableQuestion: (params: UpdateTableQuestionParams) => void;
  register: UseFormRegister<any>;
  errors?: FieldErrors<any>;
  intStatus: IntStatus;
  tableModalIsOpen: boolean;
  openTableModal: () => void;
  closeTableModal: (params: TableModalCloseParams) => void;
  addTableRow: (params: AddTableRowParams) => void;
  deleteTableRow: (params: DeleteTableRowParams) => void;
  saveTableModal: (params: TableModalSaveParams) => void;
  updateGridQuestion: (params: UpdateGridQuestionParams) => void;
  setValue: UseFormSetValue<any>;
  isReview?: boolean;
  shouldRenderTableQuestion: (params: ShouldRenderTableQuestionParams) => boolean;
  locked: boolean;
  tableId: string; 
}

const full = '100%';
const margin = 20;

const addVisibilityToQuestions = (rows: TableQuestionRecord[], visibleQuestionIds: number[]) => {
  return rows.map(row => {
    let isLocked = false;
    const questionsWithVisibility = row?.questions?.map(question => {
      const isVisible = visibleQuestionIds.includes(Number(question.questionID));
      if (question.locked) {
        isLocked = true;
      }
      return { ...question, isVisible };
    });
    return { ...row, questions: questionsWithVisibility, locked: isLocked };
  });
};

const getHorizontalPadding = (numVisibleQuestions: number) => {
  return `${margin - numVisibleQuestions}px`;
};

const getColumnStyles = (visibleQuestions: QuestionDataType[], numQuestions: number): QuestionnaireTableStyles[] => {
  return visibleQuestions.map(q => ({
    width: q.tableViewWidth !== 0 ? `${q.tableViewWidth}%` : full,
    padding: `0 0 0 5px`,
    overflow: 'hidden',
    height: 'auto',
    display: 'block'
  }));
};

const mobileColumnStyles: QuestionnaireTableStyles = {
  width: '30%',
  display: 'flex',
  alignItems: 'center',
  padding: "0 0 0 5px" 
};

const QuestionnaireTable = ({ 
  section, 
  updateTableQuestion,
  updateGridQuestion, 
  register, 
  errors, 
  intStatus,
  isReview, 
  tableModalIsOpen,
  openTableModal,
  closeTableModal,
  saveTableModal, 
  addTableRow,
  deleteTableRow,
  shouldRenderTableQuestion,
  locked,
  tableId,
}: QuestionnaireTableProps) => {
  const allQuestions = section.questions;
  const visibleQuestions = allQuestions.filter(q => q.bit_isVisibleInTable);
  const visibleQuestionIds = visibleQuestions.map(question => parseInt(question.id));
  const headers = visibleQuestions.map(q => q.label);
  const rows = addVisibilityToQuestions(section.records || [], visibleQuestionIds);
  const [selectedRecordData, setSelectedRecordData] = useState<TableQuestionRecord>();
  const tableViewColumnStyles: Array<QuestionnaireTableStyles> = getColumnStyles(visibleQuestions, visibleQuestions.length);
  const tableViewHeaderStyles = getColumnStyles(visibleQuestions, visibleQuestions.length);
  const [recordIdState, setRecordIdState] = useState(0);
  const screenWidthForMobile = 1024;
  const screenWidth = useScreenWidth();
  const [openModalId, setOpenModalId] = useState<string | null>(null);

  const handleOpenTableClick = (recordID: number) => {
    setRecordIdState(recordID);
    setOpenModalId(tableId);
    if (section.records) {
      const updatedRecords = [...section.records];
      let recordToUpdate = updatedRecords.find(record => record.recordID === recordID);

      if (recordToUpdate) {
        recordToUpdate = {
          ...recordToUpdate,
          questions: recordToUpdate.questions.map(question => ({
            ...question,
            locked: !question.locked
          }))
        };

        setSelectedRecordData(recordToUpdate);
        openTableModal();
      } 
    }
  };
  
  const handleAddTableRow = () => {
    addTableRow({ sectionId: section.sectionID });
  };
  
  const handleDeleteTableRow = (recordID: number) => {
    deleteTableRow({ sectionId: section.sectionID, recordId: recordID });
  };
  
  const handleOnModalClose = () => {
    closeTableModal({ sectionId: section.sectionID, recordId: recordIdState});
    setOpenModalId(null);
    setSelectedRecordData(undefined);
  };

  const handleModalSave = () => {
    saveTableModal({ sectionId: section.sectionID, recordId: recordIdState});
    setOpenModalId(null);
    setSelectedRecordData(undefined);
  };

  if (rows.length === 0) {
    handleAddTableRow();
  }

  const erroredRecordIds = useMemo(() => {
    return getErroredRecordIds(errors);
  }, [Object.entries(errors || {}).length]);

  const visibleQuestionsForDisplay = screenWidth > screenWidthForMobile ? visibleQuestions : visibleQuestions.slice(0, 3);

  return (
    <div>
      <table className={styles.questionnaireTable}>
        <thead>
          <tr className={styles.headerColumn}>
            {visibleQuestionsForDisplay.map((question, index) => {
              if (screenWidth > screenWidthForMobile || (screenWidth <= screenWidthForMobile && index < 3)) {
                return (
                  <th 
                    key={question.id} 
                    style={screenWidth > screenWidthForMobile ? tableViewHeaderStyles[index] : mobileColumnStyles} 
                    className={styles.tableHeader}
                  >
                    <span className={styles.tableHeaderText}>
                      {parseHTML(question.label)}
                    </span>
                  </th>
                );
              }
              return null;
            })}
            {/* Add empty header for the buttons column */ }
            {screenWidth > screenWidthForMobile && <th className={styles.tableHeader} style={{ width: '60px', padding: '0' }}/>}
          </tr>
        </thead>
        <tbody id={`${section.sectionID}-table`}>
          {rows.map((record, rowIndex) => {
            const showErrorStyle = erroredRecordIds.has(record.recordID);
            return (
              <>
                { screenWidth < screenWidthForMobile && rowIndex > 0 && 
                <tr className={styles.mobileBarRow}>
                  <td colSpan={3}/>
                </tr>}
                {record?.questions && <tr id={`${rowIndex}-rowSection`} key={`row-${rowIndex}`} className={styles.rowSection} onClick={() => handleOpenTableClick(record.recordID)}>
                  {visibleQuestionsForDisplay.map((question, qIndex) => {
                    const recordQuestion = record.questions?.find(q => String(q.questionID) === question.id);
                    if (screenWidth > screenWidthForMobile && recordQuestion?.isVisible || screenWidth <= screenWidthForMobile && qIndex < 3 && recordQuestion) {
                      return (
                        <td 
                          key={`question-${rowIndex}-${qIndex}`} 
                          className={recordQuestion.locked ? styles.locked : styles.unlocked} 
                          style={screenWidth > screenWidthForMobile ? tableViewColumnStyles[qIndex] : mobileColumnStyles}
                        >
                          <Question
                            fieldId={getFieldId({ label: question.label, recordId: record.recordID, isTableQuestion: true, isTablePreviewQuestion: true })}
                            sectionId={section.sectionID}
                            register={register}
                            updateTableQuestion={updateTableQuestion}
                            question={{ ...question, value: recordQuestion.value }}
                            locked={true}
                            errors={errors || {}}
                            lockClass={styles.locked}
                            recordId={record.recordID}
                            isReview={isReview || false}
                            isTablePreviewQuestion={true}
                            showErrorStyle={showErrorStyle}
                            intStatus={intStatus}
                          />
                        </td>
                      );
                    }
                    return null;
                  })}
                  <td className={styles.rowButtonSection}>
                    {locked ?
                      <GradientTextButton className={styles.viewButton} text="View" onClick={() => handleOpenTableClick(record.recordID)}/> :
                      <>
                        <button id={`${record.recordID}-edit-row`} className={styles.rowButton} type="button" onClick={() => handleOpenTableClick(record.recordID)}><EditTableRow /></button>
                        <button id={`${record.recordID}-delete-row`} className={styles.rowButton} type="button" onClick={e => { e.stopPropagation(); handleDeleteTableRow(record.recordID); }}><DeleteTableRow /></button>
                      </>
                    } 
                  </td>
                </tr>}
              </>
            );
          })}

        </tbody>
      </table>
      {locked ||
        <div style={{ paddingLeft: getHorizontalPadding(visibleQuestions.length) }}>
          <GradientTextButton 
            text={"Add new row"} 
            onClick={handleAddTableRow}
          />
        </div>
      }
      <EditTableModal
        section={section}
        selectedRecordData={selectedRecordData}
        isModalOpen={tableModalIsOpen && openModalId === tableId}
        saveTableModal={handleModalSave}
        handleDeleteTableRow={handleDeleteTableRow}
        register={register}
        updateGridQuestion={updateGridQuestion}
        errors={errors}
        onClose={handleOnModalClose}
        recordId={recordIdState}
        updateTableQuestion={updateTableQuestion}
        shouldRenderTableQuestion={shouldRenderTableQuestion}
        locked={locked}
      />
    </div>
  );
};
export default QuestionnaireTable;
