import React, { useEffect, useState } from "react";
import { Modal } from "components/modal/modal";
import { Button } from "components/UI/button/button";
import { QuestionTableRecord, Section, TableQuestionRecord } from "actions/questionnaireActions";
import { FieldErrors, UseFormRegister } from "react-hook-form";
import Question, { QuestionDataType } from "../questionnaireComponents/question/question";
import styles from "./editTableModal.module.scss";
import { QuestionRow } from "./questionRow/questionRow";
import { ShouldRenderTableQuestionParams, TableModalSaveParams, UpdateGridQuestionParams, UpdateTableQuestionParams } from "../questionnaireHook";
import DeleteTableRow from "components/icons/deleteTableRow";

type EditModalTableProps = {
  section: Section;
  isModalOpen: boolean;
  saveTableModal: (params: TableModalSaveParams) => void;
  handleDeleteTableRow: (recordID: number) => void;
  selectedRecordData: TableQuestionRecord | undefined;
  register: UseFormRegister<any>;
  updateGridQuestion: (params: UpdateGridQuestionParams) => void;
  errors?: FieldErrors<any>;
  onClose: (rowIndex: number) => void;
  recordId: number;
  updateTableQuestion: (params: UpdateTableQuestionParams) => void;
  shouldRenderTableQuestion: (params: ShouldRenderTableQuestionParams) => boolean;
  locked: boolean;
};

type QuestionGroups = {
  [gridId: number]: QuestionDataType[];
};

export const EditTableModal = ({
  section,
  isModalOpen,
  register,
  updateGridQuestion,
  errors,
  selectedRecordData,
  recordId,
  onClose,
  updateTableQuestion,
  saveTableModal,
  handleDeleteTableRow,
  shouldRenderTableQuestion,
  locked,
}: EditModalTableProps) => {
  const [questionGroups, setQuestionGroups] = useState<QuestionGroups>({});
  const modalSave = () => {
    saveTableModal({ sectionId: section.sectionID, recordId });
  };

  useEffect(() => {
    const filteredQuestions = section.questions.reduce<QuestionGroups>((groupedQuestions, question) => {
      const isVisible = shouldRenderTableQuestion({ sectionId: section.sectionID, recordId: recordId, questionId: question.id });
      if (!isVisible) return groupedQuestions;

      const newGroupedQuestions = { ...groupedQuestions };

      if (!newGroupedQuestions[question.grid_id]) {
        newGroupedQuestions[question.grid_id] = [];
      }

      newGroupedQuestions[question.grid_id].push(question);

      return newGroupedQuestions;
    }, {});

    setQuestionGroups(filteredQuestions);
  }, [updateTableQuestion]);
  
  return (
    <Modal id='editTableModal' title={section.txtLabel} show={isModalOpen} onClose={() => onClose(recordId)} className={styles.editTableModal}>
      <div className={styles.line}></div>
      <div className={styles.questionsContainer}>
        <div key={section.txtLabel} className={styles.section}>
          {selectedRecordData &&
            Object.entries(questionGroups).map(
              ([gridId, questions]) => (
                <QuestionRow
                  key={gridId}
                  gridId={gridId}
                  questions={questions}
                  section={section}
                  updateGridQuestion={updateGridQuestion}
                  selectedRecordData={selectedRecordData}
                  recordId={recordId}
                  register={register}
                  errors={errors}
                  updateTableQuestion={updateTableQuestion}
                  locked={locked}
                />
              )
            )}
        </div>
      </div>
      <div className={styles.buttonDivDesktop}>
        <Button
          id={'saveModalButton'}
          className={styles.submitButton}
          style="gradient"
          onClick={() => {
            modalSave();
          }}
        >
          {locked ? "Close" : "Save"}
        </Button>
      </div>
      <div className={styles.buttonDivMobile}>
        { !locked && <Button
          id={'deleteModalButton'}
          className={styles.submitButton}
          style="gradient"
          onClick={() => {
            handleDeleteTableRow(recordId),
            onClose(recordId);
          }}
        >
          <DeleteTableRow />
        </Button>}
        <Button
          id={'saveModalButton'}
          className={styles.submitButton}
          style="gradient"
          onClick={() => {
            modalSave();
          }}
        >
          {locked ? "Close" : "Save"}
        </Button>
      </div>

    </Modal>
  );
};
