import { useState } from "react";
import styles from "./appPage.module.scss";
import Layout from "components/layout/Layout";
import { DashboardAppRow } from "components/dashboardAppRow/dashboardAppRow";
import { useLocation } from "react-router-dom";
import { useGetQuestionnaires } from "actions/questionnaireActions";
import { useGetApplicationInfo, ApplicationInfo } from "actions/applicationsActions";
import NoDataMessage from "components/noDataBlock/noDataMessage";
import { Filter } from "components/UI/filter/filter";
import { SearchInput } from "components/UI/searchInput/searchInput";
import { Modal } from "components/modal/modal";
import Questionnaire, { QuestionnaireDataParams } from "components/questionnaire/questionnaire";
import { BackArrow } from "components/UI/backArrow/backArrow";
import { IntStatus } from "utils/commonFunctions";
import SingleFilterDropDown, { OptionItem } from "components/UI/singleFilterDropDown/singleFilterDropDown";
import classNames from "classnames";

const filterOptions = [
  {id: '1', label: 'Active'},
  {id: '4', label: 'Approved'},
  {id: '0', label: 'Get Started'},
  {id: '2', label: 'Pending Review'},
  {id: '3', label: 'Rejected'},
];

const sortOptions: OptionItem[] = [
  {
    label: 'Status - Alphabetical (A to Z)',
    value: { orderBy: "Status", sortOrder: "ASC" },
  },
  {
    label: 'Status - Alphabetical (Z to A)',
    value: { orderBy: "Status", sortOrder: "DESC" },
  }
];
interface LocationState {
  description: string;
  clientNumber: string;
  fileId: string;
}

export const Application = () => {
  const location = useLocation();
  const { state } = location || {};
  const { clientNumber, description, fileId } = state as LocationState || {};
  const [showQuestionnaireModal, setShowQuestionnaireModal] = useState(false);
  const [selectedQuestionnaire, setSelectedQuestionnaire] = useState<QuestionnaireDataParams>();
  const [isFilterDropdownVisible, setFilterDropdownVisible] = useState(false);
  const [isSortDropdownVisible, setSortDropdownVisible] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  const [orderBy, setOrderBy] = useState<string>("");
  const [sortOrder, setSortOrder] = useState<string>("");
  const [filter, setFilter] = useState<Array<string>>([]);
  const [hideQuestionnaireModalHeader, setHideQuestionnaireModalHeader] = useState(false);

  const { data: applicationInfo, error: applicationInfoError } = useGetApplicationInfo(fileId);
  const { 
    data: questionnairesData, 
    error: questionnairesError, 
    mutate: mutateQuestionnaires,
    noDataFound: noQuestionnairesDataFound, 
  } = useGetQuestionnaires({ fileId, filter, orderBy, sortOrder, searchWord});


  const handleSortChange = (value: any) => {
    setOrderBy(value?.orderBy || "");
    setSortOrder(value?.sortOrder || "");
  };

  const onSearch = (value: string) => {
    setSearchWord(value);
  };

  const onFilterChange = async (selectedOptions: string[]) => {
    setFilter(selectedOptions);
  };

  const onClickViewQuestionnaire = (clickedQuestionnaire: any) => {
    setSelectedQuestionnaire({
      title: clickedQuestionnaire.name,
      fileID: clickedQuestionnaire.fileId, 
      questionaireID: clickedQuestionnaire.questionnaireId, 
      intStatus: clickedQuestionnaire.intStatus,
      contID: clickedQuestionnaire.contId 
    });
    setShowQuestionnaireModal(true);
  };

  const closeQuestionnaire = () => {
    setShowQuestionnaireModal(false);
  };

  const generateAddress = (applicationInfo: ApplicationInfo | undefined) => {
    // Default to n/a
    let clientAddress = 'n/a';

    // Assumes we have a street name if they have an address
    if (applicationInfo?.streetName) {
      clientAddress = `${applicationInfo.streetName},`;
    }
    
    if (applicationInfo?.unitNumber) {
      clientAddress += ` ${applicationInfo.unitNumber},`;
    }

    if (applicationInfo?.city) {
      clientAddress += ` ${applicationInfo.city},`;
    }

    if (applicationInfo?.provinceState) {
      clientAddress += ` ${applicationInfo.provinceState},`;
    }

    // Remove trailing comma if there
    clientAddress = clientAddress.replace(/,$/, "");

    return clientAddress;
  };

  return (
    <Layout>
      <div className={styles.applicationInfo}>
        <div className={styles.backArrow}>
          <BackArrow/>
        </div>
        <h2>{clientNumber} - {description}</h2>
      </div>
      <div className={styles.infoRow}>
        <div className={styles.information}>
          <div className={styles.inform}>
            <div className={styles.answer}>Employee</div>
            <div className={styles.headerName}>{applicationInfo?.applicantName || 'n/a'}</div>
            <div className={styles.subName}>Address</div>
            <div className={styles.answer}>{generateAddress(applicationInfo) || 'n/a'}</div>
            <div className={styles.subName}>Phone Number</div>
            <div className={styles.answer}>{applicationInfo?.applicantNumber || 'n/a'}</div>
            <div className={styles.subName}>Email</div>
            <div className={styles.answer}>{applicationInfo?.applicantEmail || 'n/a'}</div>
          </div>
        </div>
        <div className={styles.lawyerInfo}>
          <div className={styles.inform}>
            <div className={styles.subName}>Lawyer</div>
            <div className={styles.clientHeaderName}>{applicationInfo?.responsibleName || 'n/a'}</div>
            <div className={styles.subName}>Phone Number</div>
            <div className={styles.answer}>{applicationInfo?.responsiblePhone || 'n/a'}</div>
            <div className={styles.subName}>Email</div>
            <div className={styles.answer}>{applicationInfo?.responsibleEmail || 'n/a'}</div>
          </div>
        </div>
        <div className={styles.analyst}>
          <div className={styles.inform}>
            <div className={styles.subName}>Case Analyst</div>
            <div className={styles.clientHeaderName}>{applicationInfo?.analystName || 'n/a'}</div>
            <div className={styles.subName}>Phone Number</div>
            <div className={styles.answer}>{applicationInfo?.analystPhone || 'n/a'}</div>
            <div className={styles.subName}>Email</div>
            <div className={styles.answer}>{applicationInfo?.analystEmail || 'n/a'}</div>
          </div>
        </div>
      </div>

      <div className={styles.filterButtons}>
        <Filter 
          id={"filter-questionnaires"} 
          text="Filter Questionnaires" 
          mobileText="Filter" 
          filterOptions={filterOptions} 
          className={styles.filter} 
          onCheckboxChange={onFilterChange}
          isVisible={isFilterDropdownVisible} 
          setVisible={setFilterDropdownVisible}
        />
        <SingleFilterDropDown 
          id={'sortDocuments'} 
          label={"Sort Questionnaires"} 
          optionItems={sortOptions} 
          onOptionSelected={handleSortChange}
          mobileText="Sort"
          dropDownDirectionClass={styles.dropDownDirectionClass}
        />
        <SearchInput id={"searchQuestionnaires"}  placeholder="Try 'questionnaire'" onChange={onSearch} className={styles.searchBar}/>
      </div>

      <div className={styles.clientTableContainerWrapper}>
        {noQuestionnairesDataFound ? 
          <NoDataMessage title="No questionnaires available." /> :
          
          <div className={styles.clientTableContainer}>
            <table className={classNames(styles.clientTable, styles.questionnairesTable)}>
              <tbody>
                {questionnairesData.map((row: any, index) => {
                  return (<DashboardAppRow
                    index={index}
                    key={row.questionnaireId}
                    header={row.name}
                    progress={row.progress ? row.progress : 0}
                    intStatus={row.intStatus as IntStatus}
                    onClick={() => onClickViewQuestionnaire(row)}
                  />);
                })}
              </tbody>
            </table>
          </div>
        }
      </div> 

      <Modal 
        id='questionnaires'
        title={`${(selectedQuestionnaire ? selectedQuestionnaire.title : 'null')}`} 
        show={showQuestionnaireModal} 
        onClose={closeQuestionnaire} 
        className={styles.questionnaireModal}
        hideModalHeader={hideQuestionnaireModalHeader}
      >
        <Questionnaire 
          selectedQuestionnaire={selectedQuestionnaire as QuestionnaireDataParams} 
          closeQuestionnaire={closeQuestionnaire} 
          mutateQuestionnaires={mutateQuestionnaires}
          isReview={false}
          setHideQuestionnaireModalHeader={setHideQuestionnaireModalHeader}
        />
      </Modal>
    </Layout>
  );
};
