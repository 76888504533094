import style from './questionnaireSection.module.scss'; 
import Question, { QuestionDataType } from '../question/question';
import QuestionnaireTable from '../questionnaireTable/questionnaireTable';
import RemoveIconRed from 'components/icons/removeIconRed';
import RemoveIcon from 'components/icons/removeIcon';
import { IntStatus, getFieldId, isComments, parentValueCheck, parseHTML } from 'utils/commonFunctions';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Section } from 'actions/questionnaireActions';
import { AddTableRowParams, DeleteTableRowParams, SectionAcceptanceParams, ShouldRenderTableQuestionParams, TableModalCloseParams, TableModalSaveParams, UpdateGridQuestionParams, UpdateTableQuestionParams } from 'components/questionnaire/questionnaireHook';
import { useMemo } from 'react';
import classNames from 'classnames';
import { Label } from 'components/UI/label/label';
import { DocumentNames } from 'actions/documentActions';
import { DocumentAdditionParams, DocumentDeletionParams } from 'components/UI/FileUpload/fileUpload';

interface SectionProps {
  section: Section;
  index: number;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>
  errors: FieldErrors<any>;
  intStatus: IntStatus;
  updateGridQuestion: (params: UpdateGridQuestionParams) => void;
  tableModalIsOpen: boolean;
  openTableModal: () => void;
  closeTableModal: (params: TableModalCloseParams) => void;
  addTableRow: (params: AddTableRowParams) => void;
  deleteTableRow: (params: DeleteTableRowParams) => void;
  setSectionAcceptance: (params: SectionAcceptanceParams) => void;
  saveTableModal: (params: TableModalSaveParams) => void;
  updateTableQuestion: (params: UpdateTableQuestionParams) => void;
  isReview: boolean;
  reviewStatus: IntStatus;
  documentNames: DocumentNames;
  addDocuments: (params: DocumentAdditionParams) => void;
  deleteDocument: (params: DocumentDeletionParams) => void;
  shouldRenderTableQuestion: (params: ShouldRenderTableQuestionParams) => boolean;
  isLockedQuestionnaire: boolean;
  isLockedTable: (sectionId: number) => boolean;
  onClickDocument: (documentName: string, documentPath: string) => void;
}

export type QuestionGroups = {
  [id: number]: QuestionDataType[];
}

interface QuestionsValueMap {
  [key: string]: string | undefined;
}

const groupQuestionsByGridId =(questions: QuestionDataType[]): QuestionGroups => {
  return questions.reduce<QuestionGroups>((groupedQuestions, question) => {
    if (!groupedQuestions[question.grid_id]) {
      groupedQuestions[question.grid_id] = [];
    }
    groupedQuestions[question.grid_id].push(question);
    return groupedQuestions;
  }, {});
};
  
const full = '100%';
const margin = 20;

const QuestionnaireSection = ({ 
  section, 
  index, 
  errors, 
  intStatus,
  updateGridQuestion, 
  updateTableQuestion,
  register, 
  setValue, 
  isReview, 
  tableModalIsOpen,
  openTableModal,
  closeTableModal,
  saveTableModal,
  addTableRow,
  deleteTableRow,
  setSectionAcceptance,
  reviewStatus,
  documentNames,
  addDocuments,
  deleteDocument,
  shouldRenderTableQuestion,
  isLockedQuestionnaire,
  isLockedTable,
  onClickDocument,
}: SectionProps) => {
  const questionsValueMap: QuestionsValueMap = section.questions.reduce((acc, question) => {    
    acc[question.id.toString()] = question.value;
    return acc;
  }, {} as QuestionsValueMap);
  let tableLabel = undefined;
  if (section.bit_isTable) {
    tableLabel = section.questions.find(q => q.fieldtype === 'LABEL')?.label;
  }

  const sectionColorClass = useMemo(() => {
    const colorClass = style.defaultColorClass;

    const errorKeys = new Set(Object.entries(errors).map(error => error[0]));
    const labelMap = new Map(section.questions.map(q => [q.id, q.label]));

    // makes the table header red if there's an error in the table
    if (section.bit_isTable && !isReview) {
      const hasAcceptanceErrors = section.accepted === false && reviewStatus !== IntStatus.PendingReview;
      const hasFieldErrors = Boolean(section.records?.some(record => {
        return record.questions?.some(recordQuestion => {
          const fieldId = getFieldId({
            label: labelMap.get(String(recordQuestion.questionID)) || "",
            recordId: record.recordID,
            isTableQuestion: true,
          });

          return errorKeys.has(fieldId);
        });
      }));
      
      if (hasAcceptanceErrors || hasFieldErrors) {
        return style.rejectedColorClass;
      }
    }

    return colorClass;
  }, [Object.entries(errors).length, section.accepted]);

  const sectionShouldRender = () => {
    const isCommentsSection = section.questions.some(question => isComments(question.label));
    const isPendingReview = reviewStatus === IntStatus.PendingReview;
    
    return isReview || !isCommentsSection || !isPendingReview;
  };

  return (
    <> {
      sectionShouldRender() && 
      <div key={section.txtLabel} className={classNames(style.section, sectionColorClass)}>
        <div className={style.title}>
          <div className={style.titleContent}>
            <div className={style.titleNumber}>{index + 1}</div>
            <div className={style.titleText}>{parseHTML(section.txtLabel)}</div>
          </div>
          {isReview && section.bit_isTable && !isLockedQuestionnaire && !section.accepted && (
            <button 
              className={style.rejectButton} 
              type="button" 
              onClick={() => setSectionAcceptance({ 
                sectionId: section.sectionID, 
                accepted: section.accepted === false ? null : false,
              })}
            >
              {section.accepted === false ? <RemoveIconRed /> : <RemoveIcon />}
            </button>
          )}
        </div>
        <div className={style.line}></div>
        {section.bit_isTable ? (
          <>
            {tableLabel && 
              <div className={style.sectionLabel}>
                <Label
                  label={tableLabel}
                />
              </div>
            }
            <QuestionnaireTable
              tableId={`table-${section.sectionID}`}
              section={section}
              errors={errors}
              intStatus={intStatus}
              updateTableQuestion={updateTableQuestion}
              updateGridQuestion={updateGridQuestion}
              setValue={setValue}
              tableModalIsOpen={tableModalIsOpen}
              openTableModal={openTableModal}
              closeTableModal={closeTableModal}
              saveTableModal={saveTableModal}
              addTableRow={addTableRow}
              deleteTableRow={deleteTableRow}
              register={register} 
              isReview={isReview}
              shouldRenderTableQuestion={shouldRenderTableQuestion}
              locked={isLockedTable(section.sectionID)}
            />
          </>
        ) : (
          Object.entries(groupQuestionsByGridId(section.questions)).map(([gridId, questions]) => {
            const typedQuestions = questions as QuestionDataType[];
            return (
              <div key={gridId} className={style.questionRow}>
                {typedQuestions.map(question => {
                  const questionShouldRender = question.parentID == null || parentValueCheck(question.parentValue, questionsValueMap[question.parentID]?.toString(), question.matchParentValues);
                  return (
                    <div 
                      key={question.id}
                      className={style.question}
                      style={{ 
                        width: question.width !== 0 ? `${question.width}%` : `${full}`, 
                        padding: `0 ${margin - questions.length}`,
                        visibility: questionShouldRender ? 'visible' : 'hidden',
                        height: questionShouldRender ? 'auto' : '0',
                      }}>
                      <Question 
                        fieldId={getFieldId({ label: question.label, isTableQuestion: false })}
                        sectionId={section.sectionID} 
                        question={question} 
                        updateGridQuestion={updateGridQuestion} 
                        register={register} 
                        errors={errors} 
                        locked={question.locked}
                        isReview={isReview}
                        isTablePreviewQuestion={false}
                        isLockedQuestionnaire={isLockedQuestionnaire}
                        documentNames={documentNames}
                        addDocuments={addDocuments}
                        deleteDocument={deleteDocument}
                        onClickDocument={onClickDocument}
                        intStatus={intStatus}
                      />
                    </div>
                  );
                })}
              </div>
            );
          })
        )}
      </div>
    } </>
  );
};

export default QuestionnaireSection;
