import { useHistory } from "react-router-dom";
import style from "./searchBarRow.module.scss";
import { RoutePaths } from "app/routing/routing";

enum searchBarRowTypes {
  File = 'File',
  Matter = 'Matter',
  Client = 'Client',
}
export interface searchBarRowProps {
  id: string;
  objectType: string;
  description: string;
  clientNumber: string;
  fileNumber: string;
}

export const SearchBarRow = ({ id, objectType, description, clientNumber, fileNumber }: searchBarRowProps) => {  
  const history = useHistory();

  const routeToPage = () => {
    if(objectType == searchBarRowTypes.Client) {
      history.push({
        pathname: RoutePaths.Company,
        state: {
          clientID: id,
          clientName: description,
          clientNumber: clientNumber,
        },
      });    
    } else if(objectType == searchBarRowTypes.Matter) {
      history.push({
        pathname: RoutePaths.Application,
        state: {
          fileId: id,
          description: description,
          clientNumber: clientNumber,

        }
      });
    }
  };

  return (
    <div id={id} className={style.searchBarRow} onClick={() => routeToPage()}>
      <div className={style.searchBarRowContent}>
        <div className={style.searchBarValue}>
          {objectType == searchBarRowTypes.Matter 
            ? 
            <div>{clientNumber}/{fileNumber} - {description}</div> : 
            <div>{clientNumber} - {description}</div>}
        </div>
        <div className={style.searchBarType}>
          {objectType}
        </div>
      </div>
    </div>
  );
};
