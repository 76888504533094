import * as base from "./lib/baseActions";
import { jwtData, apiEndpoints } from "../config/config";
import { hasRefreshExpired, is2FAVerified } from "./lib/jwtActions";
import useSWR from "swr";
import { UpdatePasswordFieldProps } from "components/resetPasswordForm/resetPasswordForm";

// Fetch data for logged in user
const fetchUser = async () => {
  try {
    const userInfo = await base.get(apiEndpoints.usersMe);
    return userInfo.data;
  }
  catch {
    return null;
  }
};

const getUser = async (): Promise<UserProfileData> => {
  const result = await base.get(apiEndpoints.usersMe);
  return result.data;
};

export const useGetUser = () => {
  const { data, error, isLoading, mutate } = useSWR([apiEndpoints.usersMe], () => getUser());
  return { data, error, isLoading, mutate};
};


export interface loginUserData {
  email: string;
  password: string;
  passwordConfirmation?: string;
}

export interface VerifyTwoFactorData {
  twoFactorCode: string;
  authToken: string | null;
}

export type SetupTwoFactorData = {
  secret: string;
  qrCode: string;
};

export interface signupUserData {
  email: string;
  password: string;
  passwordConfirmation?: string; 
}

export interface authResult {
  data: {
    authToken: string;
    expiresIn?: number;
    refreshToken?: string;
    refreshExpiresIn?: number;
    userType: string;
  }
}

export interface loginUserResult {
  data: {
    email?: string;
    firstName?: string;
    lastName?: string;
  };
}

export enum UserRoles {
  Client = "CLIENT",
  Standard = "STANDARD",
}

export interface UserProfileData {
  email: string;
  userType?: string;
  id: string;
  firstName: string;
  lastName: string;
  streetAddress: string;
  unitNumber: string;
  townCity: string;
  provinceState: string;
  country: string;
  postalCode: string;
  phoneNumber: string;
  authResult: authResult["data"];
}

export interface ResetPasswordData {
  email: string;
}

export interface forgotPasswordData {
  resetKey: string;
  password: string;
}

export type PasswordVersion = {
  passwordVersion: string;
};

export interface EmailExistsError {
  error: string;
}

const setUserAuth = (loginResult?: authResult) => {
  if (loginResult) {
    localStorage.setItem(jwtData.authToken, loginResult.data.authToken);
    localStorage.setItem(jwtData.authTokenExpiry, `${loginResult.data.expiresIn}`);
    const { refreshToken, refreshExpiresIn } = loginResult.data;
  
    if (refreshToken) {
      localStorage.setItem(jwtData.refreshToken, refreshToken);
    }
  
    if (refreshExpiresIn) {
      localStorage.setItem(jwtData.refreshTokenExpiry, `${refreshExpiresIn}`);
    }
  }
};

const loginUser = async (data : loginUserData) : Promise<authResult> => {
  const { email, password } = data;

  try {
    const loginResult: authResult = await base.post(apiEndpoints.login, {
      email,
      password
    });
    setUserAuth(loginResult);
    return loginResult;
  } catch (err: any) {

    if(err.status === 400) {
      err.message = "Incorrect Email or Password.";
    }

    throw new Error(`Incorrect Email or Password`);
  }
};

const forgotPasswordEmail = async (data: ResetPasswordData) => {
  try {
    const changePasswordResult: authResult  = await base.put(apiEndpoints.forgotPasswordEmail, { email: data.email });
    return changePasswordResult;
  } catch (err: any) {

    if(err.status === 400) {
      err.message = "Incorrect Password.";
    }

    throw new Error(`Server Error, unable to change password.`);
  }
};

const forgotPassword = async (data: forgotPasswordData) => {
  const changePasswordResult: authResult  = await base.put(apiEndpoints.forgotPassword, { resetKey: data.resetKey, password: data.password});
  setUserAuth(changePasswordResult);
  return changePasswordResult;
};

const updatePassword = async (data: UpdatePasswordFieldProps) => {
  try {
    const changePasswordResult: authResult  = await base.put(apiEndpoints.updatePassword, { oldPassword: data.oldPassword, newPassword: data.newPassword});
    setUserAuth(changePasswordResult);
    return changePasswordResult;
  } catch (err: any) {
    if(err.status === 400) {
      err.message = "update password failed.";
    }
    throw new Error(err);
  }
};

const verifyTwoFactor = async (data: VerifyTwoFactorData): Promise<UserProfileData | null> => {
  const { twoFactorCode } = data;

  try {
    const verifyResult = await base.post(apiEndpoints.verify2FA, { code: twoFactorCode, authToken: data.authToken});
    return verifyResult.data;
  } catch (err: any) {
    if (err.status === 400) {
      err.message = "Invalid Code";
    }
    throw new Error("Invalid Code");

  }
};

const logoutUser = async () => {
  localStorage.removeItem(jwtData.authToken);
  localStorage.removeItem(jwtData.refreshToken);
  localStorage.removeItem(jwtData.refreshTokenExpiry);
  localStorage.removeItem(jwtData.authTokenExpiry);
};

const createUser = async (data: signupUserData) => {
  const registrationCheck = checkRegistrationInformation(data);

  if (!registrationCheck.success) {
    throw registrationCheck.errors;
  } else {
    try {
      const userResult = await base.post(apiEndpoints.users, data);
      return userResult;
    } catch (err: any) {
      throw err?.original?.response;
    }
  }
};

/// check confirmation helper function
const checkRegistrationInformation = (data : signupUserData) => {
  const check : { success: boolean; errors?: Error[] } ={ success: true};
  const errors = [];
  if(!data.email || data.email.length < 5) {
    // does not have email
    errors.push(new Error("You must fill in a valid email"));
  }

  if (!data.password || !data.passwordConfirmation) {
    errors.push(new Error("You must fill in the password and password confirmation field"));    
  } else if (data.password.length < 8) {
    errors.push(new Error("The password must be a minimum length of 8"));
  } else if (data.password !== data.passwordConfirmation) {
    errors.push(new Error("The password and confirmation password must match"));
  }

  if(errors.length > 0) {
    check.success=false;
    check.errors = errors;
  }

  return check;
};

const userIsAuthorized = (): boolean => {
  const initialAuthToken = localStorage.getItem(jwtData.authToken);
  const refreshToken = localStorage.getItem(jwtData.refreshToken);
  
  const isRefreshTokenExpired = hasRefreshExpired();

  const validRefreshToken = !!refreshToken && !isRefreshTokenExpired && is2FAVerified(refreshToken);

  if (refreshToken && isRefreshTokenExpired) {
    localStorage.clear();
    window.location.reload();
    return false;
  }

  return !!initialAuthToken && validRefreshToken;
};



const updateUser = async (data: UserProfileData) => {
  const { firstName, lastName, streetAddress, unitNumber, townCity, provinceState, country, postalCode, phoneNumber } = data;
  
  const dataToSave = {
    ...(firstName && { firstName }),
    ...(lastName && { lastName }),
    streetAddress,
    unitNumber,
    townCity,
    provinceState,
    country,
    postalCode,
    phoneNumber,
  };

  await base.put(apiEndpoints.updateUser, dataToSave);

  return fetchUser();
};


// Function to set a cookie with a specific value
function setCookie(value: string) {
  const date = new Date();
  date.setTime(date.getTime() + (7 * 24 * 60 * 60 * 1000)); // 7 day expiry
  const expires = "expires=" + date.toUTCString();
  document.cookie = "user" + "=" + value + ";" + expires + ";path=/";
}

// Function to get the value of a cookie by its username
function getCookie() {
  const cookieName = "user=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');

  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return "";
}


const setup2FA = async (): Promise<SetupTwoFactorData> => {
  try {
    const response = await base.post(`${apiEndpoints.setup2FA}`, null);
    return response.data;
  } catch (err: any) {
    throw new Error(err.message);
  }
};



const getHasVerified2FA = async (): Promise<any> => {
  const result = await base.get(`${apiEndpoints.hasVerified2fa}`);
  return result.data;
};

const useGetHasVerified2FA = () => {
  const { data, error, isLoading } = useSWR(`${apiEndpoints.hasVerified2fa}`, () => getHasVerified2FA());
  return { data, error, isLoading };
};

const getUserPasswordVersion = async (): Promise<PasswordVersion> => {
  const result = await base.get(`${apiEndpoints.passwordVersion}`);
  return result.data;
};

const getUserEmailCheck = async (data: { email: string }): Promise<EmailExistsError | null> => {
  const result = await base.get(`${apiEndpoints.existingEmailCheck}`, {
    params: { email: data.email },
  });
  return result.data;
};

export {
  fetchUser,
  loginUser,
  forgotPasswordEmail,
  forgotPassword,
  updatePassword,
  verifyTwoFactor,
  logoutUser,
  createUser,
  userIsAuthorized,
  updateUser,
  setCookie,
  getCookie,
  setUserAuth,
  setup2FA,
  useGetHasVerified2FA,
  getHasVerified2FA,
  getUserPasswordVersion,
  getUserEmailCheck,
};
